<template>
  <span style="text-align: center">
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button> &nbsp;
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="componentGroup"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('componentGroup.name')" prop="name">
            <el-input v-model="componentGroup.name" :placeholder="$t('placeholder.input') + $t('componentGroup.name')"/>
          </el-form-item>
          <el-form-item :label="$t('componentGroup.sort')" prop="sort">
            <el-input v-model="componentGroup.sort" :placeholder="$t('placeholder.input') + $t('componentGroup.sort')"/>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/componentGroup'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveComponentGroupBtn",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      componentGroup: {},
      rules: {
        name: [
          {required: true, message: this.$t('placeholder.input') + this.$t('componentGroup.name'), trigger: 'blur'},
        ],
        sort: [
          {required: true, message: this.$t('placeholder.input') + this.$t('componentGroup.sort'), trigger: 'blur'},
        ],
      },
      
      setIng: false,
    }
  },
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.componentGroup = {}
      this.setIng = false
      if (id) {
        this.getInfo(id)
      }
    },
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.componentGroup = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.componentGroup))
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.componentGroup.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('xzdListPageReload', !this.componentGroup.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
          
        }
      });
    }
  }
}
</script>
<style scoped>
</style>