<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="componentGroup"
    :get-list-func="getList"
    :queryParam="queryParam"
    can-select
    :show-export-excel-button="false"
    :show-import-excel-button="false">
    <div slot="searchBox">
      <componentGroup-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <save-componentGroup-button @xzdListPageReload="xzdListPageReload"/>
      <delete-componentGroup-button :ids="selectedRows.map(v=>v.id)" @xzdListPageReload="xzdListPageReload"/>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column :label="$t('componentGroup.name')" width="200">
        <template slot-scope="scope">
          {{scope.row.name}}
          <span v-if="scope.row.userId * 1 === 0" style="color: #409EFF">({{$t('componentGroup.systemGroup')}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" :label="$t('componentGroup.sort')"/>
      <el-table-column :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <save-componentGroup-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
          <delete-componentGroup-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/componentGroup";
import ComponentGroupListSearchBox from "./ComponentGroupListSearchBox";
import SaveComponentGroupButton from "../buttons/SaveComponentGroupButton";
import DeleteComponentGroupButton from "../buttons/DeleteComponentGroupButton";

export default {
  name: "ComponentGroupList",
  components: {DeleteComponentGroupButton, SaveComponentGroupButton, ComponentGroupListSearchBox},
  data() {
    return {
      queryParam: {},
      getList,
    }
  },
  mounted() {
    console.log('ComponentGroupList - mounted')
  },
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    },
  }
}
</script>
<style scoped>
</style>